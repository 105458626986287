import React from "react";

const Wave = (props) => {
	return(
		<svg className="bg-wave" width="3840" height="150" style={{
            position: 'absolute',
            bottom: '-1px',
            left: '50%',
            zIndex: 9,
            transform: 'translateX(-50%)',
        }}>
            <path d="M3839 149.5H-1c74.36-1.605 150.725-3.837 229.094-6.696 221.516-8.08 321.984-67.618 548.616-72.244 74.158-1.514 269.455 14.036 314.054 16.338 107.71 5.56 220.833 5.654 339.37.281 251.428-11.395 581.013-46.117 865.18-70.939C2484.76-.307 2679.404-4.21 2879.25 4.534c88.526 7.665 144.768 13.21 168.724 16.632 205.7 29.385 415.795 85.758 544.775 102.294 48.153 6.174 130.237 14.854 246.251 26.04z"></path>
        </svg>
     );
}

export default Wave;
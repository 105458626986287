import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from "react-router-dom"
import Wave  from './wave'

const AboutSection = (props) => {

    const accomplish = [
        {
            counter: '12',
            title: 'years of experience',
            content: 'Over a decade of coding magic and caffeine!'
        },
        {
            counter: '100',
            title: 'expertise industries',
            content: 'Uniting Technology and Diverse Business Needs.'
        },
        {
            counter: '150',
            title: 'completed projects',
            content: 'Each one a new story of innovation and success.'
        },
        {
            counter: '2',
            title: 'years avg project duration',
            content: "We're not just coders, we're family, growing together."
        },
    ]
	return(
		<section id="hp-about-section">
			<Container>
				<Row id="hp-about-row">
					<Col lg={5}>
						<div className="section-heading">
							<h2>Powering Startups with Innovative Solutions</h2>
							<p>Since 2011, Codenroll has been a dynamic force in software and automation solutions, dedicated to startups and businesses. Our path is adorned with a variety of projects, from client-focused ventures to altruistic non-profit initiatives. Rooted in transparency and a zeal for innovative technologies, we are not just developers — we are your committed tech partners, turning your ideas into digital success stories.</p>
							<nav className="nav-horizontal" style={{
								marginTop: 45,
							}}>
								<Link to={'/about'} className="btn btn-default">Uncover Our Roots</Link>
							</nav>
						</div>
					</Col>
					<Col lg={7}>
						<ul id="accomplish-list">
                            {accomplish.map((item, index) => <li key={index}>
								<h3><span>{item.counter}+</span>{item.title}</h3>
								<p>{item.content}</p>
							</li>)}
						</ul>
					</Col>
				</Row>
			</Container>
			<Wave />
        </section>
	);
}

export default AboutSection;
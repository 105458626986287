import React, { useState, createContext, useEffect } from 'react';

export const MainContext = createContext();

export const ContextProvider = props => {

    // Interface states
    const [portfolio, setPortfolio] = useState([])



    const getPortfolio = async () => {
        await fetch("https://admin.codenroll.co.il/wp-json/wp/v2/case_study?per_page=99")
            .then(response => {
                return response.json();
            })
            .then(results => {
                setPortfolio(results);
            });
    };

  useEffect(() => {
    getPortfolio();
  }, []);


  return (
    <MainContext.Provider
      value={{
        portfolio: [portfolio],
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
};
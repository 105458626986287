import React, {useContext, useEffect} from "react";
import { Link } from "react-router-dom";
import {
  Container, Breadcrumb, Row, Col,
} from 'react-bootstrap';
import { HomeFilled } from '@ant-design/icons';
import { MainContext } from '../context'

const CaseStudies = (props) => {
    const context = useContext(MainContext);
    const [portfolio] = context.portfolio
	
	useEffect(() => {
		props.declareAppClasses();
		document.title = "Web Development Case Studies | Codenroll";
	})
	
	let Item1 = portfolio.length > 0 ? portfolio[0] : '';
	let Item2 = portfolio.length > 1 ? portfolio[1] : '';
	let Item3 = portfolio.length > 2 ? portfolio[2] : '';
	let Item4 = portfolio.length > 3 ? portfolio[3] : '';
	let Item5 = portfolio.length > 4 ? portfolio[4] : '';
	let Item6 = portfolio.length > 5 ? portfolio[5] : '';
	let Item7 = portfolio.length > 6 ? portfolio[6] : '';
	let Item8 = portfolio.length > 7 ? portfolio[7] : '';
	let Item9 = portfolio.length > 8 ? portfolio[8] : '';
	let Item10 = portfolio.length > 9 ? portfolio[9] : '';
	let Item11 = portfolio.length > 10 ? portfolio[10] : '';
	let Item12 = portfolio.length > 11 ? portfolio[11] : '';
		// console.log('portfolio', portfolio)
	return(
		<div className="page-wrap">
			<header className="page-header">
				<Container>
					<h1>Case Studies</h1>
					<Breadcrumb>
						<li className="breadcrumb-item"><Link to={'/'} title="Navigate to Home page"><HomeFilled /><span className="sr-only">Navigate to Home page</span></Link></li>
						<Breadcrumb.Item active>Case Studies</Breadcrumb.Item>
					</Breadcrumb>
				</Container>
			</header>
			
			<section id="hp-case-studies">
				<Container>
					<Row className="hp-use-cases-row">
					{Item1 !== '' &&
						<Col sm={4}>
							<Link to={'/case-studies/' + Item1.slug} className="box box-image uc-box-long">
								<div className="bg" style={{ backgroundImage: 'url(' + Item1.full_image + ')', }}></div>
								<div className="textual">
									<h3>{ Item1.title.rendered }</h3>
								</div>
							</Link>
						</Col>
					}
					{Item2 !== '' &&
						<Col sm={8}>
							<Row>
								<Col sm={12}>
									<Link to={'/case-studies/' + Item2.slug} className="box box-image uc-box-wide">
										<div className="bg" style={{ backgroundImage: 'url(' + Item2.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item2.title.rendered }</h3>
										</div>
									</Link>
								</Col>
								
								{Item3 !== '' &&
								<Col sm={6}>
									<Link to={'/case-studies/' + Item3.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item3.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item3.title.rendered }</h3>
										</div>
									</Link>
								</Col>
								}
								{Item4 !== '' &&
								<Col sm={6}>
									<Link to={'/case-studies/' + Item4.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item4.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item4.title.rendered }</h3>
										</div>
									</Link>
								</Col>
								}
							</Row>
						</Col>
					}
					</Row>
					
					<Row className="hp-use-cases-row hp-use-cases-row-2">
					
					{Item5 !== '' &&
						<Col sm={8}>
							<Row>
								<Col sm={12}>
									<Link to={'/case-studies/' + Item5.slug} className="box box-image uc-box-wide">
										<div className="bg" style={{ backgroundImage: 'url(' + Item5.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item5.title.rendered }</h3>
										</div>
									</Link>
								</Col>
								{Item6 !== '' &&
								<Col sm={6}>
									<Link to={'/case-studies/' + Item6.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item6.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item6.title.rendered }</h3>
										</div>
									</Link>
								</Col>
								}
								{Item7 !== '' &&
								<Col sm={6}>
									<Link to={'/case-studies/' + Item7.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item7.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item7.title.rendered }</h3>
										</div>
									</Link>
								</Col>
								}
							</Row>
						</Col>
					}
					{Item8 !== '' &&
						<Col sm={4}>
							<Link to={'/case-studies/' + Item8.slug} className="box box-image uc-box-long">
								<div className="bg" style={{ backgroundImage: 'url(' + Item8.full_image + ')', }}></div>
								<div className="textual">
									<h3>{ Item8.title.rendered }</h3>
								</div>
							</Link>
						</Col>
					}
					</Row>
					
					<Row className="hp-use-cases-row">
					{Item9 !== '' &&
						<Col sm={4}>
							<Link to={'/case-studies/' + Item9.slug} className="box box-image uc-box-long">
								<div className="bg" style={{ backgroundImage: 'url(' + Item9.full_image + ')', }}></div>
								<div className="textual">
									<h3>{ Item9.title.rendered }</h3>
								</div>
							</Link>
						</Col>
					}
					{Item10 !== '' &&
						<Col sm={8}>
							<Row>
								<Col sm={12}>
									<Link to={'/case-studies/' + Item10.slug} className="box box-image uc-box-wide">
										<div className="bg" style={{ backgroundImage: 'url(' + Item10.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item10.title.rendered }</h3>
										</div>
									</Link>
								</Col>
								
								{Item11 !== '' &&
								<Col sm={6}>
									<Link to={'/case-studies/' + Item11.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item11.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item11.title.rendered }</h3>
										</div>
									</Link>
								</Col>
								}
								{Item12 !== '' &&
								<Col sm={6}>
									<Link to={'/case-studies/' + Item12.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item12.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item12.title.rendered }</h3>
										</div>
									</Link>
								</Col>
								}
							</Row>
						</Col>
					}
					</Row>
				</Container>
        	</section>
		</div>
	);
}

export default CaseStudies;
import React, {useState, useEffect} from "react";
import {
  Row, Col, Container, Breadcrumb, Form, Button,
} from 'react-bootstrap';
import { Link } from "react-router-dom";
import {validateEmail} from '../utilities';
import { MailFilled, CompassFilled, PhoneFilled, HomeFilled, LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons';
// import $ from "jquery";

const Contact = (props) => {
	
	const [fullname, setFullname] = useState('');
	const [company, setCompany] = useState('');
	const [email, setEmail] = useState('');
	const [role, setRole] = useState('');
	const [message, setMessage] = useState('');
	const [phone, setPhone] = useState('');
	const [errors, setErrors] = useState([]);
	const [disabled, setDisabled] = useState(true);
	const [sending, setSending] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	
	 const addTask = async (e) => {
	 	e.preventDefault();
	 	
        
		try {
			setSending(true);
			let collectErrors = [];
    
	    	if( fullname.length <= 1 || !fullname )
	    		collectErrors.push('fullname');
	    
		    if( !validateEmail(email) )
		    	collectErrors.push('email');
	      
		    if( message.length <= 2 )
		    	collectErrors.push('message');
	
			if( collectErrors.length === 0 ){
				setSending(true);
			} else {
				setErrors(collectErrors);
				return false;
			}
			
			let task = 'Name: ' + fullname + ' \n';
			task += 'Company: ' + company + ' \n';
			task += 'Tel: ' + phone + ' \n';
			task += 'Email: ' + email + ' \n';
			task += 'Role: ' + role + ' \n';
			task += 'Message: ' + message + ' \n';
			task += '\n\nSource: Codenroll.co.il';
			
			const response = await fetch('https://app.asana.com/api/1.0/tasks?opt_fields=name,due_on,completed,memberships.section.name&project=1202549578523471', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer 1/76469592662994:3dc4db3c60625604a4407d39af524d1a',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "data": {
                      "approval_status": "pending",
                      "completed": false,
                      "external": {
                        "data": "A blob of information",
                      },
                      "html_notes": "<body>" + task + "</body>",
                      "name": 'Lead: ' + fullname,
                      "notes": fullname,
                      "projects": [
                        '1202549578523471'
                      ],
                      "resource_subtype": "default_task",
                      
                      "workspace": "76418763542074"
                    }
                  })
            });
			const json = await response.json();
            if( json.data ){
                setCompany('');
                setFullname('');
                setEmail('');
                setErrors([]);
                setMessage('');
                setPhone('');
                setRole('');
                setShowSuccess(true);
                setSending(false);
            }
            // setSending(false);
            // setSuccess(true);
		  } catch (error) {
			  //console.error(error);
		  } finally {
			//setLoading(false);
		}
	};
	
	useEffect(() => {
		props.declareAppClasses();
	});
  
  const validate = () => {
    let collectErrors = [];
    
    var re = /\S+@\S+\.\S+/;

    if( fullname.length <= 1 || !fullname )
      collectErrors.push('fullname');
    
    if( !re.test(email) )
      collectErrors.push('email');
      
    if( message.length < 2 )
      collectErrors.push('message');

    if( collectErrors.length === 0 ){
      setErrors(collectErrors);
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }
	
	return(
		<div className="page-wrap">
        <header className="page-header page-header-nomarg">
            <Container>
              <h1>Contact Us</h1>
              
              <Breadcrumb>
                <li className="breadcrumb-item"><Link to={'/'} title="Navigate to Home page"><HomeFilled /><span className="sr-only">Navigate to Home page</span></Link></li>
                <Breadcrumb.Item active>Contact</Breadcrumb.Item>
              </Breadcrumb>
            </Container>
          </header>
          
        <Container>
            <Row>
                <Col lg={5}>
                    <div className="box widget">
                        <div className="widget-heading-wrap">
                            <h2 className="widget-heading">Contact With Us</h2>
                        </div>
                
                        <dl className="sl-horizontal">
                            <dt><CompassFilled /> Address</dt>
                            <dd>18 HaHarduf, Atlit<br />Israel</dd>
                            
                            <dt><PhoneFilled /> Phone</dt>
                            <dd><a href="972534626311">(+972) 53 4626 311</a></dd>
                            
                            <dt><MailFilled /> Email</dt>
                            <dd><a href="mailto:a@Codenroll.co.il">a@Codenroll.co.il</a></dd>
                        </dl>
                    </div>
                </Col>
                <Col lg={1}></Col>
                <Col lg={6}>
                  <div className="box widget">
                    <div className="widget-heading-contact">
                        <h2 className="widget-heading">Let's talk about your project</h2>
                        <p>Our team is ready to answer any of your questions and requests:</p>
                    </div>
                    {!sending && !showSuccess &&
                    <Form
                              name="contact-project"
                              //initialValues={{ remember: true }}
                              //onFinish={onFinish}
                              //onFinishFailed={onFinishFailed}
                            >
                              <Form.Group controlId="fullname" className={errors.includes('fullname') ? 'is-error' : ''}>
                                <Form.Label>Your Full Name *</Form.Label>
                                <Form.Control type="text" name="fullname" value={fullname} onChange={(e) => {
                                  validate();
                                	setFullname(e.target.value);
                                }} />
                                {errors.includes('fullname') &&
                                  <p>Please fill in both first name and last name </p>
                                }
                              </Form.Group>
                              
                              <Form.Group controlId="company">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control type="text" name="company" value={company} onChange={(e) => {
                                  validate();
                                	setCompany(e.target.value);
                                }} />
                              </Form.Group>
                              
                              <Form.Group controlId="email" className={errors.includes('email') ? 'is-error' : ''}>
                                <Form.Label>Email *</Form.Label>
                                <Form.Control type="email" name="email" value={ email} onChange={(e) => {
                                  validate();
                                	setEmail(e.target.value);
                                }} />
                                {errors.includes('email') &&
                                  <p>You must fill in an email address  </p>
                                }
                              </Form.Group>
                              
                              <Form.Group controlId="phone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="tel" name="phone" value={phone} onChange={(e) => {
                                  validate();
                                	setPhone(e.target.value);
                                }} />
                              </Form.Group>
                              
                              <Form.Group controlId="role">
                                <Form.Label>What is your role?</Form.Label>
                                <Form.Control as="select" name="role" value={role} onChange={(e) => {
                                  validate();
                                	setRole(e.target.value);
                                }}>
                                  <option value=""></option>
                                  <option value="clevel">C-level/SVP</option>
                                  <option value="director">VP/Director</option>
                                  <option value="manager">Manager</option>
                                  <option value="individual">Individual Contributor</option>
                                  <option value="other">Other</option>
                                </Form.Control>
                              </Form.Group>
                              
                              <Form.Group controlId="message" className={errors.includes('message') ? 'is-error' : ''}>
                                <Form.Label>Message *</Form.Label>
                                <Form.Control as="textarea" rows={3} name="message" value={message} onChange={(e) => {
                                  validate();
                                	setMessage(e.target.value);
                                }} />
                                {errors.includes('message') &&
                                  <p>Please detail your request as much as possible  </p>
                                }
                              </Form.Group>
                              {/*
                              <Form.File id="attach" style={{
                                marginBottom: 30,
                              }}>
                                <Form.File.Label>Attach File</Form.File.Label>
                                <Form.File.Input name="file" value={ form_data.file} onChange={updateForm} />
                              </Form.File>
                              */}
                              <Button type="submit" variant="primary" disabled={disabled} onClick={addTask} style={{
                                marginTop: 45,
                              }}>Request a quote</Button>
                    </Form>
                    }
                    {sending &&
                      <div id="contact-loader"><LoadingOutlined /></div>
                    }
                    {showSuccess &&
                      <div id="contact-success">
                        <div>
                          <CheckCircleOutlined />
                        </div>
                        <h5 style={{
                          margin: '15px 25% 60px',
                        }}>Your request has been sent successfully.</h5>
                      </div>
                    }
                  </div>
                </Col>
            </Row>
        </Container>
      </div>
	);
}

export default Contact;
import React, {useState, useEffect} from "react";
import parse from 'html-react-parser';
import { Link, useParams} from "react-router-dom";
import { Container, Breadcrumb, Row, Col } from 'react-bootstrap'
import { HomeFilled } from '@ant-design/icons';

const CaseStudy = (props) => {
	const [mockClass, setMockClass] = useState('');
	const [data, setData] = useState(null);
	
	let match = useParams(); //'/case-study/:slug' 
	
	useEffect(() => {
		const getData = async () => {
            await fetch("https://admin.codenroll.co.il/wp-json/wp/v2/case_study?slug=" + match.slug, {})
                .then(response => {
                    return response.json();
                })
                .then(results => {
                    document.title = results[0].title.rendered + " (Case Study) | Codenroll";
                    setData(results[0]);
                    
                    
                    setTimeout(function(){
                        setMockClass('in');
                    }, 1200);
                });
        }
        getData()
		props.declareAppClasses();
		
		
	}, [match.slug, props]);
	
	let title = data && data.title ? parse(data.title.rendered) : '';
	let intro = data && data.intro ? parse(data.intro) : '';
	let challenge = data && data.challenge ? parse(data.challenge) : '';
	let solution = data && data.content ? parse(data.content.rendered) : '';
	let mockup = data && data.mockup ? data.mockup : '';
	let industry = data && data.industry ? data.industry : '';
	let hours = data && data.hours ? data.hours : '';
	let tech = data && data.tech ? data.tech : '';
	let integrations = data && data.integrations ? data.integrations : '';
	let duration = data && data.duration ? data.duration : '';
	
	return(
		<div className="page-wrap">
			{data &&
			<header className={"page-header page-header-with-lead " + mockClass} id="page-haeder-case-study">
				<Container>
					<Row>
						<Col lg={6}>
						{title !== '' &&
						<div className="content">
							<h1>{title}</h1>
							{intro !== '' &&
							<div className="lead"><p>{intro}</p></div>
							}
							{window.innerWidth > 481 &&
								<Breadcrumb>
									<li className="breadcrumb-item"><Link to={'/'} title="Navigate to Home page"><HomeFilled /><span className="sr-only">Navigate to Home page</span></Link></li>
									<li className="breadcrumb-item"><Link to={'/case-studies'} title="Navigate to Case Studies">Case Studies</Link></li>
									<Breadcrumb.Item active>{title}</Breadcrumb.Item>
								</Breadcrumb>
							}
						</div>
					}
						</Col>
						<Col lg={6}>
							{mockup !== '' &&
							<div className={"mockup-wrap " + mockClass}>
								<div className="mockup">
									<img src={mockup} alt="Mockup" />
								</div>
							</div>
							}
						</Col>
					</Row>
					
				</Container>
			</header>
			}
			{data &&
			<div>
				<Container>
					<Row>
						<Col lg={6} sm={12} id="cs-challenge-col">
							<h2>The Challenge</h2>
							{challenge}
						</Col>
						<Col lg={6} sm={12}>
							<div className="box" id="case-study-overview">
								<h2 className="h4">Overview</h2>
								<dl className="dl-horizontal">
								{industry !== '' &&
								<div>
										<dt>Industry</dt>
										<dd>{industry}</dd>
									</div>
								}
								{duration !== '' &&
								<div>
										<dt>Duration</dt>
										<dd>{duration}</dd>
									</div>
								}
								{hours !== '' &&
								<div>
									<dt>Total project hours</dt>
									<dd>{hours}</dd>
									</div>
								}
								{tech !== '' &&
								<div>
									<dt>Technology</dt>
									<dd>{tech}</dd>
									</div>
								}
								{integrations !== '' &&
								<div>
									<dt>Integrations</dt>
									<dd>{integrations}</dd>
									</div>
								}
									
								</dl>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			}
			{data &&
			<div id="case-study-solution">
				<Container>
					<Row>
						<Col lg={6}>
							<h2>The Solution</h2>
							{solution}
						</Col>
					</Row>
				</Container>
			</div>
			}
		</div>
	);
}

export default CaseStudy;
import React, { useContext } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { MainContext } from '../context';

const CaseStudiesSection = () => {
    const context = useContext(MainContext);
    const [portfolio] = context.portfolio
	
	let Item1 = portfolio.length > 0 ? portfolio[0] : '';
	let Item2 = portfolio.length > 1 ? portfolio[1] : '';
	let Item3 = portfolio.length > 2 ? portfolio[2] : '';
	let Item4 = portfolio.length > 3 ? portfolio[3] : '';

	return(
		<section id="hp-case-studies">
			<Container>
				<Row className="section-heading-wrap">
					<Col sm={12}>
						<div className="section-heading">
							<h2>Our Greatest Hits</h2>
						</div>
					</Col>
				</Row>
				<Row id="hp-use-cases-row">
					<Col sm={4}>
					    {Item1 !== '' &&
						<Link to={'/case-studies/' + Item1.slug} className="box box-image uc-box-long">
							<div className="bg" style={{
							    backgroundImage: 'url(' + Item1.full_image + ')',
							}}></div>
							<div className="textual">
								<h3>{ Item1.title.rendered }</h3>
							</div>
						</Link>
					    }
					</Col>
					<Col sm={8}>
						<Row>
							<Col sm={12}>
							    {Item2 !== '' &&
								<Link to={'/case-studies/' + Item2.slug} className="box box-image uc-box-wide">
									<div className="bg" style={{
							    backgroundImage: 'url(' + Item2.full_image + ')',
							}}></div>
									<div className="textual">
										<h3>{ Item2.title.rendered }</h3>
									</div>
								</Link>
							    }
							</Col>
							<Col sm={6}>
							    {Item3 !== '' &&
								<Link to={'/case-studies/' + Item3.slug} className="box box-image uc-box-small">
									<div className="bg" style={{
							    backgroundImage: 'url(' + Item3.full_image + ')',
							}}></div>
									<div className="textual">
										<h3>{ Item3.title.rendered }</h3>
									</div>
								</Link>
							    }
							</Col>
							<Col sm={6}>
							    {Item4 !== '' &&
								<Link to={'/case-studies/' + Item4.slug} className="box box-image uc-box-small">
									<div className="bg" style={{
							    backgroundImage: 'url(' + Item4.full_image + ')',
							}}></div>
									<div className="textual">
										<h3>{ Item4.title.rendered }</h3>
									</div>
								</Link>
							    }
							</Col>
						</Row>
					</Col>
				</Row>
				<div style={{
					textAlign: 'center',
					marginTop: 45,
				}}>
					<Link to="/case-studies" className="btn btn-primary">Uncover More Hits</Link>
				</div>
			</Container>
        </section>
	);
}

export default CaseStudiesSection;
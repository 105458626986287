import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { Container, Breadcrumb } from 'react-bootstrap'
import { HomeFilled } from '@ant-design/icons';

const Technologies = (props) => {
	useEffect(() => {
		props.declareAppClasses();
		document.title = "Technologies | Codenroll";
	})
		
	return(
		<div className="page-wrap">
			<header className="page-header">
				<Container>
					<h1>Technologies</h1>
					<Breadcrumb>
						<li className="breadcrumb-item"><Link to={'/'} title="Navigate to Home page"><HomeFilled /><span className="sr-only">Navigate to Home page</span></Link></li>
						<Breadcrumb.Item active>Technologies</Breadcrumb.Item>
					</Breadcrumb>
				</Container>
			</header>
			
			<section id="tech-view">
				<Container>
					<p>Here at Codenroll, we're like tech detectives, constantly on the lookout for the latest and greatest in technology - often when they're just taking their first baby steps in the market. We put these cutting-edge tech wonders under our magnifying glass, assessing their capabilities, stability, and how stretchy they can be to fit the unique puzzles of the projects we develop. Our mantra? Mix a pinch of innovation with a dash of stability – it's the secret recipe for a product that doesn’t just win races but also stands the test of time.</p>
					<p>In the lab of development, we don't just use technology; we tailor it, like a bespoke suit, to fit the exact contours of our clients' needs, market trends, and the business ecosystem of the product. Our tech wardrobe is vast, offering our clients a smorgasbord of technological options to pick from - a safe playground to find that perfect tech match.</p>
					<p>And because we believe in tech karma, we give back to the community too. We're not just using open source technologies; we're adding our own spices to the mix, developing solutions that we happily share with the world for free. Why? Because innovation isn't just our business; it's our contribution to pushing the digital world forward, one open-source project at a time.</p>
					
					<div id="tech-providers">
						<h2>Application Development</h2>
						<ul>
							<li className="box box-bordered">iOS</li>
							<li className="box box-bordered">Android SDK</li>
							<li className="box box-bordered">React Native</li>
							<li className="box box-bordered">Ionic</li>
							<li className="box box-bordered">Express</li>
							<li className="box box-bordered">Expo</li>
						</ul>
						
						<h2>Server Side</h2>
						
						<ul>
							<li className="box box-bordered">Node.js</li>
							<li className="box box-bordered">Grunt.js</li>
							<li className="box box-bordered">Gulp.js</li>
							<li className="box box-bordered">PHP</li>
							<li className="box box-bordered">symfony</li>
							<li className="box box-bordered">Laravel</li>
							<li className="box box-bordered">CodeIgniter</li>
						</ul>
						
						
						
						<h2>Client Side</h2>
						
						<ul>
							<li className="box box-bordered">React</li>
							<li className="box box-bordered">Angular</li>
							<li className="box box-bordered">jQuery</li>
							<li className="box box-bordered">HTML5</li>
							<li className="box box-bordered">CSS3</li>
							<li className="box box-bordered">Sass</li>
						</ul>
						
						<h2>Databases</h2>
						
						<ul>
							<li className="box box-bordered">MySQL</li>
							<li className="box box-bordered">SQLite</li>
							<li className="box box-bordered">PostgreSQL</li>
							<li className="box box-bordered">MongoDB</li>
							<li className="box box-bordered">MariaDB</li>
							<li className="box box-bordered">DynamoDB</li>
						</ul>
						
						
						<h2>QA Testing & Deployment</h2>
						
						<ul>
							<li className="box box-bordered">Jasmine.js</li>
							<li className="box box-bordered">Lambda</li>
							<li className="box box-bordered">Selenium</li>
							<li className="box box-bordered">Karma</li>
							<li className="box box-bordered">Jest</li>
							<li className="box box-bordered">Webpack</li>
							<li className="box box-bordered">Compass</li>
							<li className="box box-bordered">NPM</li>
							<li className="box box-bordered">YARN</li>
						</ul>
						
						<h2>Management</h2>
						
						<ul>
							<li className="box box-bordered">Git</li>
							<li className="box box-bordered">InvisionApp</li>
							<li className="box box-bordered">ASANA</li>
							<li className="box box-bordered">Jira</li>
							<li className="box box-bordered">Harvest</li>
						</ul>
						
						<h2>CMS</h2>
						
						<ul>
							<li className="box box-bordered">WordPress</li>
							<li className="box box-bordered">Magento</li>
							<li className="box box-bordered">Moodle</li>
							<li className="box box-bordered">HubSpot</li>
						</ul>
						
						{/*
						<h2>And More ...</h2>
						<ul>
							<li className="box box-bordered">Socket.io</li>
						</ul>
						*/}
					</div>
					
				</Container>
        	</section>
		</div>
	);
}

export default Technologies;